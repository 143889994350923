/***************************************************************************
		INNOVATIONS TEASER
			scoping class is nm-module-innovations
			(use for all declarations to avoid side effects)
***************************************************************************/

/* --- scope class --- */
.nm-module-innovations {
	display: flex;
	flex-flow: column nowrap;
	margin-bottom: var(--space-xxxl);
}

.nm-module-innovations-teaserpool {
	display: block;
}

.nm-module-innovations + link + .nm-module-innovations,
.nm-module-innovations-teaserpool + script + script + link + .nm-module-innovations,
.nm-module-innovations + .nm-module-innovations-teaserpool > .nm-module-innovations,
.nm-module-innovations-teaserpool + script + script + .nm-module-innovations-teaserpool > .nm-module-innovations {
	margin-top: var(--space-xxxl);
	padding-top: var(--space-xs);
}

/* --- teaser item --- */
.nm-module-innovations .nm-teaser-item {
	overflow: hidden;
	position: relative;
}

.nm-module-innovations:not(.nm-teaser-single) .nm-teaser-item + .nm-teaser-item {
	padding-top: var(--space-xs);
}

.nm-module-innovations:not(.nm-teaser-single) .nm-teaser-item + .nm-teaser-item[data-theme] {
	margin-top: var(--space-xs);
	padding-top: 0;
}

/* --- themes --- */
.nm-module-innovations [data-theme] {
	border-color: currentColor;
}

.nm-module-innovations [data-theme="black"] {
	background-color: var(--color-black);
	color: #fff;
}

.nm-module-innovations [data-theme="lightgrey"] {
	background-color: var(--color-grey-05);
	color: #000;
}

.nm-module-innovations [data-theme="darkgrey"] {
	background-color: var(--color-grey-70);
	color: #fff;
}

.nm-module-innovations [data-theme="red"] {
	background-color: var(--color-progressive-red);
	color: #fff;
}

/* --- image --- */
.nm-module-innovations .nm-image {
	display: flex;
	height: 100%;
	object-fit: cover;
	position: relative;
	width: 100%;
}

/* --- text --- */
/* textcontainer */
.nm-module-innovations .nm-text-container {
	box-sizing: border-box;
	margin-top: var(--space-s);
	padding: 0 4vw var(--space-xl);
}

.nm-module-innovations.nm-teaser-single .nm-teaser-wide .nm-text-container {
	display: none; /* text container should be hidden on single teasers with images on every viewport size */
}

.nm-module-innovations.nm-teaser-single .nm-teaser-item + .nm-teaser-wide {
	order: -1;
}

/* headline main */
.nm-module-innovations .nm-headline-main {
	margin-top: var(--space-s);
}

.nm-module-innovations .nm-headline-main,
.nm-module-innovations .nm-headline-sub {
	font-weight: var(--font-weight-bold);
}

.nm-module-innovations [data-theme] .nm-headline-main {
	color: inherit;
	margin-bottom: var(--space-xxxl);
}

.nm-module-innovations [data-theme] .nm-text-is-top-aligned .nm-headline-main {
	margin-bottom: 0;
}

/* subheadline */
.nm-module-innovations .nm-headline-sub {
	margin-top: var(--space-s);
}

.nm-module-innovations [data-theme] .nm-headline-sub {
	color: inherit;
}

/* copy & footnote */
.nm-module-innovations .nm-copy,
.nm-module-innovations .nm-module-innovations-teaser-footnote {
	margin-top: var(--space-s);
}

.nm-module-innovations-teaser-footnote + .nm-module-innovations-teaser-footnote {
	margin-top: 0;
}

.nm-module-innovations [data-theme] .nm-copy,
.nm-module-innovations [data-theme] .nm-module-innovations-teaser-footnote {
	color: inherit;
}

/* list */
.nm-module-innovations .nm-list {
	margin: var(--space-m) 0;
}

.nm-module-innovations [data-theme] .nm-list {
	color: inherit;
}

/* --- button --- */
/* buttoncontainer */
.nm-module-innovations__button-container {
	margin-top: auto;
}

/* button */
.nm-module-innovations__button {
	margin-top: var(--space-l);
}

/* eec data */

.nm-teaser-item-with-eec .nm-module-innovations__button-eec-wrapper {
	display: flex;
	flex-direction: column-reverse;
}

.nm-module-innovations .nm-teaser-item-with-eec .nm-eec-container {
	color: #000;
	margin-top: var(--space-m);
}

.nm-module-innovations .nm-teaser-item-with-eec[data-theme] .nm-eec-container {
	color: inherit;
}

.nm-module-innovations .nm-teaser-item-with-eec .nm-textcolor-is-inverted.nm-textcolor-is-inverted .nm-eec-container {
	color: #000;
}

.nm-module-innovations .nm-teaser-item-with-eec .nm-eec {
	color: inherit;
	margin-bottom: var(--space-xs);
}

.nm-module-innovations .nm-teaser-item-with-eec .nm-eec:last-child {
	margin-bottom: 0;
}

/* --- Mediaqueries --- */

@media all and (min-width: 600px) {
	.nm-module-innovations .nm-text-container {
		bottom: 0;
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between; /* default alignment is an equal distribution of textual elements with justify-content */
		left: 0;
		margin-top: 0;
		position: absolute; /* textcontainers in combination with an image container must be positioned absolute */
		right: 0;
		top: 0;
		z-index: 1;
	}

	.nm-module-innovations [data-theme] .nm-text-container {
		position: static;
	}

	/* case: textpositioning top */
	/* subheadline, text, list, smalltext */
	.nm-module-innovations .nm-text-is-top-aligned {
		justify-content: flex-start;
	}

	.nm-module-innovations .nm-headline-main,
	.nm-module-innovations .nm-headline-sub,
	.nm-module-innovations .nm-copy,
	.nm-module-innovations .nm-list,
	.nm-module-innovations .nm-module-innovations-teaser-footnote {
		border-color: currentColor;
		color: #fff; /* default textcolor on images switches to #fff */
		text-shadow: 0 0 15px rgba(0, 0, 0, .15); /* text on images with a text-shadow */
	}

	.nm-module-innovations .nm-module-innovations__button {
		text-shadow: 0 0 15px rgba(0, 0, 0, .15); /* text on images with a text-shadow */
	}

	.nm-module-innovations .nm-headline-main {
		margin-top: var(--space-l);
	}

	.nm-module-innovations [data-theme] .nm-headline-main,
	.nm-module-innovations [data-theme] .nm-headline-sub,
	.nm-module-innovations [data-theme] .nm-copy,
	.nm-module-innovations [data-theme] .nm-list,
	.nm-module-innovations [data-theme] .nm-module-innovations-teaser-footnote {
		border-color: inherit;
		color: inherit;
		text-shadow: none;
	}

	.nm-module-innovations .nm-textcolor-is-inverted .nm-headline-main,
	.nm-module-innovations .nm-textcolor-is-inverted .nm-headline-sub,
	.nm-module-innovations .nm-textcolor-is-inverted .nm-copy,
	.nm-module-innovations .nm-textcolor-is-inverted .nm-list,
	.nm-module-innovations .nm-textcolor-is-inverted .nm-module-innovations-teaser-footnote {
		color: #000; /* inverted textcolor for elements on images is #000 */
	}

	/* eec data */

	.nm-teaser-item-with-eec .nm-module-innovations__button-eec-wrapper {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: space-between;
	}

	.nm-module-innovations .nm-teaser-item-with-eec .nm-text-container {
		padding-bottom: var(--space-l);
	}

	.nm-teaser-item-with-eec .nm-module-innovations__button-container {
		margin-top: 0;
	}

	.nm-module-innovations .nm-teaser-item-with-eec .nm-eec-container {
		color: #fff;
		display: flex;
		flex-wrap: wrap;
	}

	.nm-module-innovations .nm-teaser-item-with-eec .nm-eec {
		margin-bottom: 0;
		margin-right: var(--space-l);
		margin-top: var(--space-xs);
	}

	/* background-gradient */

	.nm-module-innovations .nm-background-gradient {
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
		bottom: 0;
		height: 30%;
		left: 0;
		position: absolute;
		width: 100%;
	}

	.nm-module-innovations .nm-background-gradient--white {
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
	}

	.nm-module-innovations .nm-innovation-teaser-item-image {
		height: 100%;
	}
}

@media all and (min-width: 1024px) {
	.nm-module-innovations {
		flex-flow: row nowrap; /* teasers side by side to each other */
	}

	.nm-module-innovations-teaserpool + .nm-module-innovations-teaserpool {
		padding-top: var(--space-xs);
	}

	.nm-module-innovations .nm-teaser-item {
		flex: 1 1 0; /* their space is distributed evenly as default */
	}

	.nm-module-innovations.nm-teaser-single .nm-teaser-item {
		flex-basis: .5%; /* their space is distributed evenly as default */
	}

	.nm-module-innovations .nm-teaser-item.nm-teaser-wide {
		flex: 2 1 0; /* basis value fixes a sizing problem with the scaled image */
	}

	.nm-module-innovations.nm-teaser-single .nm-teaser-item.nm-teaser-wide {
		flex-basis: .5%; /* basis value fixes a sizing problem with the scaled image */
	}

	.nm-module-innovations .nm-teaser-item:first-of-type {
		margin-right: var(--space-xs); /* teaser gap */
	}

	.nm-module-innovations .nm-image-container {
		align-items: flex-start;
		flex-direction: column;
		flex-wrap: wrap;
	}

	.nm-module-innovations.nm-teaser-single .nm-teaser-item:first-of-type {
		display: flex;
		margin-right: 0; /* delete the margin for single teasers */
	}

	.nm-module-innovations:not(.nm-teaser-single) .nm-teaser-item + .nm-teaser-item {
		padding-top: 0;
	}

	.nm-module-innovations:not(.nm-teaser-single) .nm-teaser-item + .nm-teaser-item[data-theme] {
		margin-top: 0;
	}

	.nm-module-innovations.nm-teaser-single .nm-teaser-item + .nm-teaser-wide { /* reset mobile order of items */
		order: 1;
	}

	.nm-module-innovations.nm-teaser-single .nm-text-container {
		order: -1;
	}

	.nm-module-innovations [data-theme] .nm-text-container {
		height: 100%; /* forcing the container to stretch to the bottom of it's parent */
	}

	.nm-module-innovations.nm-teaser-single.nm-textbox-position .nm-text-container {
		order: 1;
	}

	.nm-module-innovations .nm-teaser-wide .nm-text {
		max-width: 64%; /* max width copy (only on wide teasers) */
	}

	/* eec data */

	.nm-module-innovations .nm-teaser-item-with-eec:not(.nm-teaser-wide) .nm-eec-container { /* one-third-teaser -> eec always in row */
		display: block;
	}
}

@media all and (min-width: 1921px) {
	.nm-module-innovations .nm-text-container {
		padding-left: 76.8px; /* viewport size is no longer controlling the padding, it freezes and inherits the value from 4% of 1920px */
		padding-right: 76.8px;
	}
}

@media all and (max-width: 599px) {
	.nm-innovation-teaser-item-image .nm-module-innovations__button,
	.nm-innovation-teaser-item-image .nm-module-innovations__button:active {
		border-color: var(--color-black);
		color: var(--color-black);
	}

	.nm-innovation-teaser-item-image .nm-module-innovations__button:hover,
	.nm-innovation-teaser-item-image .nm-module-innovations__button:focus {
		border-color: var(--color-grey-70);
		color: var(--color-grey-70);
	}
}
